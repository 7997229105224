<template>
  <Alert :isActive="isError" v-if="isError">{{ errorMessage }}</Alert>
  <Loading :isShow="isLoading" v-if="!isError">
    <Navigation></Navigation>
    <div class="theme-container">
      <PageTitle
        :title="pageTitle"
        :imgSrc="`${headerImage}`"
      ></PageTitle>
      <v-template v-if="pageDescription">
        <div class="container">
          <p class="subtitle" v-html="`${pageDescription.text}`" />
        </div>

      </v-template>
      <v-template v-if="cards && cards.length > 0">
        <div class="section-container nav-card">
          <div class="container">
            <div class="rectangleCard-container ps-3 pe-3">
              <div class="nav-title mb-3">We dive into far greater detail in each of the following sections, covering:</div>
              <div class="d-flex row">
                <div class="col-12 col-sm-6">
                  <v-template v-for="index in Math.ceil(cards.length/2)">
                    <div class="mb-2">
                      <a :href="`#eventcard${index-1}`">{{cards[index-1].title}}&#x2193;</a>
                    </div>
                  </v-template>
                </div>
                <div class="col-12 col-sm-6">
                  <v-template v-for="index in cards.length-Math.ceil(cards.length/2)">
                    <div class="mb-2">
                      <a :href="`#eventcard${index+Math.ceil(cards.length/2)-1}`">{{cards[index+Math.ceil(cards.length/2)-1].title}}&#x2193;</a>
                    </div>
                  </v-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-template>
      <v-template v-for="card,index in cards" :key="index">

        <div :class="`section-container ${index+1 == cards.length? 'last':''}`" :id="`eventcard${index}`">
                        <div class="container">

        <ExpandCard
          :title="card.title"
          :content="
            card.description
          "
          :optionalContent="[
            card.subdescription,
          ]"
          :imgSrc="card.image"
          :flipImage="true"
          :linkList="[
            card.link
          ]"
        />
      </div>
      </div>
      </v-template>
      <v-template v-if="thankYouSection">
                <div class="container">

      <ThankYouCard 
        :title="thankYouSection.title"
        :nameColumns="thankYouSection.nameColumns"
        :description="thankYouSection.description"
        :subdescription="thankYouSection.subdescription"
        />
        </div>
      </v-template>
    </div>
    <BottomNavigator :id="lookingAheadPageId" />
  </Loading>
</template>

<script>
import BottomNavigator from "../components/BottomNavigator.vue";
import ExpandCard from "../components/ExpandCard.vue";
import Navigation from "../components/Navigation.vue";
import PageTitle from "../components/PageTitle.vue";
import ThankYouCard from "../components/ThankYouCard.vue";
import Loading from "@/components/Loading.vue";
import api from "@/services/api.js";
import Alert from "@/components/Alert.vue";

export default {
  data: () => ({
    thankYouCardNames: [],
    isLoading: false,
    isError: false,
    errorMessage: "",
    pageTitle: "",
    headerImage: "",
    pageDescription: ""
  }),
  computed: {
    lookingAheadPageId() {
      const lookingAheadPage = this.$store.state.pages.lookingahead;
      if(lookingAheadPage){
        return lookingAheadPage.id;
      }
      return null;
    },
  },
  methods: {
    async getData() {
      this.isError = false;
      this.isLoading = true;
      try {
        const { data } = await api.getLookingAheadPage(this.lookingAheadPageId);
        this.pageTitle = data.pageTitle;
        this.headerImage = data.pageIcon;
        this.pageDescription = data.pageDescription;
        this.cards = data.cards.map(card => ({
          ...card,
          description: card.description ? card.description.text : null,
          link: {
            link:card.link.url,
            openInWindow: card.link.openInWindow
          }
        }));
        this.thankYouSection = {
          ...data.thankYouSection,
          nameColumns: data.thankYouSection.nameColumns.map(nameColumn => nameColumn.names),
          description: data.thankYouSection.description ? data.thankYouSection.description.text: null,
          subdescription: data.thankYouSection.subdescription ? data.thankYouSection.subdescription.text: null,
        }
      } catch (err) {
        this.isError = true;
        this.errorMessage = err.message;
      } finally {
        this.isLoading = false;
      }
    },
  },
  watch:{
    lookingAheadPageId(newValue, oldValue) {
      if (newValue) {
        this.getData()
      }
    }
  }, 
  async beforeRouteUpdate(to, from, next) {
    next();
  },

 async created() {
    if(this.lookingAheadPageId){
		  await this.getData();
    }  
  },

  components: {
    PageTitle,
    Navigation,
    ExpandCard,
    ThankYouCard,
    BottomNavigator,
    Loading,
    Alert,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  @include relative;
  :deep(.page-title) {
    .title-container {
      background-color: $primary-color-5;
    }
  }
  .section-container {
    :deep(.rectangleCard-container) {
      background-color: $secondary-color-9;
    }
    :deep(h1) {
      color: $font-color-1;
    }
  }
}

.subtitle {
  padding-top:2em;
  padding-bottom:1em;

}
.section-container {
  background-color: $secondary-color-4;
  &.last{
    padding-bottom: 5em;
      @include responsive(phone) {
      @include padding(1 1 1 0);
    }
    @include responsive(tablet) {
      @include padding(4 4 4 4);
    }
  }
  @include padding(10 10 0 10);
  z-index: 1;
  @include responsive(phone) {
    @include padding(1 1 1 0);
  }
  @include responsive(tablet) {
    @include padding(4 4 4 4);
  }
}

.nav-card{
  .nav-title{
    font-size:2em;
    font-weight:700;
  }
}

.top {
  padding-top: 150px;
  @include responsive(phone) {
    padding-top: 20px;
  }
}

.bottom {
  @include responsive(phone) {
    @include padding(1 1 1 1);
  }
}

.feedback-section {
  background-color: #f5f5f5;
  @include padding(3 17 3 17);
  @include margin(0 0 5 0);
  @include responsive(phone) {
    @include padding(2 2 2 2);
  }
}

.feedback-contact-text {
  color: $secondary-color-1;
  font-weight: 600;
}
.rectangleCard-container {
  background-color: $secondary-color-4;
  @include padding(8 8 8 8);
  &.margined{
      @include padding(8 8 8 0);
        margin-left:5em;
      @include responsive(tablet) {
        @include margin(5 5 5 5);
        @include padding(5 5 5 0);
      }
  }
  border-bottom-right-radius: 4em;
  position: relative;

  @include responsive(phone) {
    @include margin(1 1 1 1);
    @include padding(4 0 4 0);
  }
  @include responsive(tablet) {
    @include margin(5 5 5 5);
    @include padding(5 5 5 5);
  }
}
</style>
