<template>
  <div class="main-content-container">
    <h1 v-if="title">{{title}}</h1>
    <template v-if="description">
      <h3 v-html="description"></h3>
    </template>
    <div class="flex-container columns">
      <div class="flex-3 column" v-for="column in nameColumns" :key="column">
        <div class="name-columns" v-for="name in column" :key="name">
          {{name}}
        </div>
      </div>
    </div>
    <template v-if="subdescription">
      <h3 class ="bottom-message" v-html="subdescription"></h3>
    </template>
  </div>
</template>

<script>

export default {
    props: [
    "description",
    "subdescription",
    "nameColumns",
    "title",
  ],
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.main-content-container {
  background-color: $secondary-color-4;
  @include padding(10);
  border-radius: 8px 8px 56px 8px;
  @include margin(4 0 15 0);
  @include responsive(phone) {
    @include padding(3 3 3 3);
    @include margin(2 2 4 2);
  }
  @include responsive(tablet) {
  @include padding(4 3 4 3);
    @include margin(4 4 4 4);
  }
}

h1 {
  font-size: 48px;
}

.bottom-message {
  color: $secondary-color-1;
  font-size: 24px;
}

.column {
  font-weight: 300;
  @include responsive(phone) {
    font-size: 12px;
  }
  @include responsive(tablet) {
    font-size: 12px;
  }
}

.columns {
  @include margin(4 0 4 0);
 
}

// .name-columns {
//   width: 50%;
// }

</style>
